
import { getEquipmentQuery } from '@/api/equipment';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { Box, VStack, useToast } from '@chakra-ui/react';
import { QueryClient, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { LoaderFunction, useLoaderData, useParams } from 'react-router-dom';
import { useState } from 'react';
import { addEquipmentToMission, getMissionEquipmentsPlanningForEquipment, getMissionEquipmentsPlanningForEquipmentKey, removeEquipmentFromMission } from '@/api/missionEquipment';
import { addDays, addHours, endOfMonth, format, formatISO, parseISO, startOfMonth } from 'date-fns';

import { VehiculesListQueryParams } from './VehiculesList';
import { formatMissionEquipmentsforEquipmentCalendar } from '@/utils/equipment';
import MissionDetailForEquipmentModal from '@/screens/MissionDetailForEquipmentModal/MissionDetailForEquipmentModal';
import { VehiculeReservationBoxModal } from '@/screens/EquipmentActionButton/VehiculeReservationBox/Modal';
import { EquipmentCalendar } from '@/components/EquipmentCalendar/EquipmentCalendar';

export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const VehiculeCalendar = () => {

  const { orgId, equipmentId } = useParams()
  const queryClient = useQueryClient()
  const toast = useToast()

  const [selectedEvent, setSelectedEvent] = useState<{ id: RecordId, mission_id: RecordId, equipment_id: RecordId } | null>(null)
  const [selectedRange, setSelectedRange] = useState<VehiculesListQueryParams | null>(null)


  const [currentRange, setCurrentRange] = useState([startOfMonth(new Date()), endOfMonth(new Date())])

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const equipmentPlanningKey = [getMissionEquipmentsPlanningForEquipmentKey, equipmentId, currentRange]

  const planning = useInfiniteQuery({
    queryKey: equipmentPlanningKey,
    queryFn: ({ pageParam }) => equipmentId ? getMissionEquipmentsPlanningForEquipment(
      equipmentId,
      pageParam,
      { by_period: { started_at: addDays(currentRange[0], -3), ended_at: addDays(currentRange[1], 3) } }
    ) : null,
    initialPageParam: 1,
    getNextPageParam: (lastPage, pages) => lastPage?.meta?.next,
})

  const { mutate: deleteMe, isSuccess, data: deletedMe, reset } = useMutation({
    mutationFn: (missionEquipmentId: RecordId) => removeEquipmentFromMission(missionEquipmentId),
    onSuccess: async (data, variables, context) => {
      await queryClient.invalidateQueries({ queryKey: equipmentPlanningKey })
      await planning.refetch()
    }
  })

  const { mutate: mutateAddEquipmentToMission } = useMutation({
    mutationFn: ({ equipmentId, missionId }: { equipmentId: RecordId, missionId: RecordId }) => (
      addEquipmentToMission(equipmentId, missionId)
    ),
    onError: (err, params, context) => {
      console.log("onError", { err, params, context })
      toast({ title: `Erreur lors de la réservation`, description: err['equipmentId'] || err.message , status: 'error', isClosable: true, duration: 2000 })
    },
    onSettled: (data, error, variables) => {
      queryClient.invalidateQueries({ queryKey: equipmentPlanningKey })
    }
  })

  const onSelectEvent = (event: any) => {
    console.log({ event })
    setSelectedEvent(event)
  }

  const onSelectSlot = (date: string) => {
    const start = parseISO(date)
    const params: VehiculesListQueryParams = {
      available_between: { start_date: formatISO(start), end_date: formatISO(addHours(start, 12)) },
    }
    console.log("Slot:", { event, params })
    setSelectedRange(params)
  }

  const onCancelEvent = (missionEquipmentId: RecordId) => {
    console.log("onCancelEvent", { missionEquipmentId })
    if (missionEquipmentId) {
      deleteMe(missionEquipmentId)
    }
    setSelectedEvent(null)
  }


  const onRangeChange = ({ start, end }: { start: string, end: string }) => {
    setCurrentRange([parseISO(start), parseISO(end)])
    console.log('onRangeChange', { start, end }, { currentRange })
  }

  const onNewReservation = async (equipmentId: RecordId, missionId: RecordId) => {
    setSelectedRange(null)
    console.log({ equipmentId, missionId })
    await mutateAddEquipmentToMission({ equipmentId, missionId })
  }

  const onOpen = () => {

  }

  if (!history) {
    return <></>
  }

  return (
    <Box px={{ base: 0, md: 3 }} py={3}>
      <Box bg="bg.surface">

          <VStack spacing={3} alignItems={'stretch'}>
            <EquipmentCalendar
              query={planning}
              formatEvents={formatMissionEquipmentsforEquipmentCalendar}
              selectedDate={format(currentRange[0], 'yyyy-MM-dd HH:mm')}
              callbacks={{
                onEventClick: onSelectEvent,
                onRangeUpdate: onRangeChange,
                onDoubleClickDate: onSelectSlot,
                onDoubleClickDateTime: onSelectSlot,
              }}
            />
          </VStack>
        <MissionDetailForEquipmentModal
          onClose={() => setSelectedEvent(null)}
          isOpen={selectedEvent !== null}
          onCancel={onCancelEvent}
          equipmentId={selectedEvent?.equipment_id}
          missionIds={[selectedEvent?.mission_id || '']}
        />
        <VehiculeReservationBoxModal
          onClose={() => setSelectedRange(null)}
          isOpen={selectedRange !== null}
          queryParams={selectedRange}
          equipmentId={initialData?.data?.id || equipmentId || ''}
          equipment={initialData?.data?.attributes}
          onTakeout={onNewReservation}
          onOpen={onOpen}
        />
      </Box>
    </Box>
  );
}

export default VehiculeCalendar;