import { equipmentRoute, missionRoute, structureRoute } from '@/constants/routes'
import { IconType } from 'react-icons'
import { BsCircle, BsGrid3X3Gap, BsGrid, BsSquare, BsDiamond } from 'react-icons/bs'

export interface EntityLabel {
  label: string,
  link: (orgId, id) => string,
  icon: IconType,
  color: string,
}

const entities: { [key: string]: EntityLabel } = {
  mission: {
    label: "Mission",
    link: (orgId, id) => missionRoute(orgId, id),
    icon: BsCircle,
    color: 'nodes'
  },
  equipment: {
    label: "Equipement",
    link: (orgId, id) => equipmentRoute(orgId, id),
    icon: BsGrid3X3Gap,
    color: 'deployments'
  },
  user: {
    label: "Utilisateur",
    link: (orgId, id) => "",
    icon: BsDiamond,
    color: 'replicasets'
  },
  equipmenttype: {
    label: "Type d'équipement",
    link: (orgId, id) => equipmentRoute(orgId, id),
    icon: BsGrid,
    color: 'pods'
  },
  structure: {
    label: "Structure",
    link: (orgId, id) => structureRoute(orgId, id),
    icon: BsSquare,
    color: 'containers'
  },
}

export default entities