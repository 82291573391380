import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalProps,
  VStack,
  HStack,
  Text,
  Divider,
} from '@chakra-ui/react'

import { RecordId } from '@/interfaces/id';
import { useNanuqQuery } from '@/hooks/query';
import { RemoteFetchContainer } from '@/components/RemoteFetchContainer/RemoteFetchContainer';
import { getEquipment, getEquipmentKey } from '@/api/equipment';
import { VehiculeDetails } from '../VehiculeDetails/VehiculeDetails';
import { TbCalendar } from 'react-icons/tb';
import MissionQueryBox from './MissionQueryBox';

export interface MissionDetailForEquipmentModalProps extends Partial<ModalProps> {
  isOpen: boolean
  onClose: () => void
  onCancel: (missionEquipmentId: RecordId) => void
  missionIds?: RecordId[] | undefined
  equipmentId?: RecordId | undefined
}

const MissionDetailForEquipmentModal = ({ isOpen, onClose, onCancel, missionIds, equipmentId, ...props }: MissionDetailForEquipmentModalProps) => {

  const equipmentQuery = useNanuqQuery([getEquipmentKey, equipmentId], () => equipmentId ? getEquipment(equipmentId) : null)

  return (
    <Modal {...props} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <RemoteFetchContainer {...equipmentQuery} render={(equipment) => (
          <ModalContent>
            <ModalHeader>{equipment?.data.attributes.name}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={3} alignItems={'stretch'}>
                {equipment && equipment.data.meta?.equipment_type.category == "vehicule" && <VehiculeDetails data={equipment?.data} />}
                <Divider />
                <VStack flex={1} alignItems={'stretch'}>
                  <HStack alignItems={'center'} py={3}>
                    <Text color='brand.600'><TbCalendar /></Text>
                    <Text fontSize={'lg'}><b>Réservations</b></Text>
                  </HStack>
                  <VStack divider={<Divider />} spacing={3} flex={1} alignItems={'stretch'}>
                  {(missionIds || []).map(missionId => <MissionQueryBox key={missionId} equipmentId={equipmentId} missionId={missionId} onCancel={onCancel} />)}
                  </VStack>
                </VStack>
              </VStack>
            </ModalBody>
          </ModalContent>
        )} />
      </Modal>
  )
}

export default MissionDetailForEquipmentModal