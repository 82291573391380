import * as React from "react"
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Input,
  ModalFooter,
  InputGroup,
  InputLeftElement,
  Icon,
  InputRightElement,
  Stack,
  HStack,
  Text,
} from "@chakra-ui/react"

import { FiSearch } from "react-icons/fi"
import { searchInOrganization, searchInOrganizationKey } from "@/api/search"
import { useDebounce } from "use-debounce"
import { useParams } from "react-router-dom"
import { useNanuqQuery } from "@/hooks/query"
import { SearchTable } from "../../screens/SearchResultsTable/SearchResultsTable"
import { useKeyPressEvent } from 'react-use';
import { Kbd } from '@chakra-ui/react'

export const GlobalSearch = (
) => {
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure()
  const { orgId, structureId } = useParams()

  const initialRef = React.useRef(null)

  const [searchValue, setSearchValue] = React.useState("");
  const [search] = useDebounce(searchValue, 500);

  const onSearch = (search: string) => {
    setSearchValue(search);
  }

  useKeyPressEvent('/', (e) => {
    if (!isOpen && (!document.activeElement || document.activeElement?.tagName !== "INPUT")) {
      onOpen()
    }
  })

  const handleInputClick = (e) => {
    onToggle()
  }

  const handleClose = () => {
    onClose()
    document.body.blur()
  }

  const queryKey = [searchInOrganizationKey, orgId, structureId, search]
  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery(
    queryKey,
    () => (orgId && search.length) ? searchInOrganization(
      orgId,
      search,
      {
        structure: [structureId],
      }
    ) : null
  )

  return (
    <>

      <Button onClick={handleInputClick} leftIcon={<FiSearch />} variant='ghost'>
        <HStack>
          <Text color={'fg.subtle'}>Chercher</Text>
          <Kbd>/</Kbd>
        </HStack>
      </Button>
      <Modal
        initialFocusRef={initialRef}
        isOpen={isOpen}
        onClose={handleClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody mt={8} pb={6}>
            <Stack spacing={4}>
              <FormControl>
                <InputGroup marginTop={2}>
                  <InputLeftElement pointerEvents="none">
                    <Icon as={FiSearch} color="muted" boxSize="5" />
                  </InputLeftElement>
                  <Input ref={initialRef} value={searchValue} placeholder="Rechercher..." onChange={(e) => onSearch(e.target.value)} />
                </InputGroup>
              </FormControl>
              {data?.data && <SearchTable onClose={handleClose} data={data} />}
            </Stack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
