
import { getMission, getMissionKey, getMissionQuery } from '@/api/mission';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import StateBadge from '@/components/StateBadge/StateBadge';
import { TabNav } from '@/components/TabNav/TabNav';
import { TabNavButton } from '@/components/TabNavButton/NavButton';
import { missionEditRoute, missionEquipmentsRoute, missionRoute, missionSummaryRoute, missionsRoute, structureRoute, structuresRoute } from '@/constants/routes';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { MissionAttributesMeta, MissionWithEquipmentsAttributes } from '@/interfaces/mission';
import { ApiResourceResponse } from '@/utils/api';
import { formatDateShort } from '@/utils/datesFormat';
import { QueryClient } from '@tanstack/react-query';
import { parseISO } from 'date-fns';
import { TbArrowLoopRight2, TbBoxSeam, TbSettings } from 'react-icons/tb';
import { LoaderFunction, NavLink, Outlet, useLoaderData, useParams } from 'react-router-dom';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getMissionQuery(params.missionId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getMissionQuery(params.missionId as RecordId))
    } 
    return data
  }
)

export const MissionPage = () => {

  const { orgId, missionId } = useParams()

  const initialData = useLoaderData() as ApiResourceResponse<MissionWithEquipmentsAttributes, unknown, MissionAttributesMeta>;

  const {
    isLoading,
    data,
    error,
    refetch,
    status,
  } = useNanuqQuery([getMissionKey, missionId], () => missionId ? getMission(missionId) : null, { initialData })

  const { ability } = useOrganizations()
  const canUpdate = ability?.can('update', 'mission', data?.data.attributes)

  return (
    <PageWithTitle
      isLoading={isLoading}
      // breadcrumbs={[
      //   { label: 'Structures', url: structuresRoute(orgId) },
      //   { label: data?.data?.meta?.structure?.name, url: structureRoute(orgId, data?.data?.attributes.structure_id) },
      //   { label: 'Missions', url: missionsRoute(orgId) },
      //   { label: data?.data?.attributes?.name, url: missionRoute(orgId, missionId) },
      // ]}
      title={data?.data?.attributes?.name}
      subtitle={data?.data?.attributes && `${data?.data?.attributes?.mission_type} le ${formatDateShort(parseISO(data?.data?.attributes?.begin_at))}`}
      actions={[
        // <StateBadge key={'state'} state={data?.data?.attributes.current_state} />,
        // <Button key={'edit'} size={'sm'} as={Link} variant='secondary' to={missionEditRoute(orgId, missionId)}>Modifier</Button>
      ]}
      stackProps={{px: 0}}
    >
      <BoxSection px={0} py={0}>

        <TabNav>
          <TabNavButton icon={TbArrowLoopRight2} label='Résumé' as={NavLink} to={missionSummaryRoute(orgId, missionId)} />
          {/* <TabNavButton icon={TbBoxSeam} label='Matériel' as={NavLink} to={missionEquipmentsRoute(orgId, missionId)} /> */}
          {canUpdate && <TabNavButton icon={TbSettings} label='Paramètres' as={NavLink} to={missionEditRoute(orgId, missionId)} />}
          {/* <TabNavButton icon={TbArrowLoopRight2} label='Missions' as={NavLink} to={missionsForMissionRoute(orgId, missionId)} /> */}
        </TabNav>
        <Outlet />
      </BoxSection>
    </PageWithTitle>
  );
}

export default MissionPage;