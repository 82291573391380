import { ROUTES } from "../constants/routes";
import { SearchAttributes, SearchAttributesMeta } from "../interfaces/search";
import { RecordId } from "../interfaces/id";
import { ApiCollectionResponse, defaultAuthHeaders, handleApiResponse } from "../utils/api";
import qs from "qs";


export const searchInOrganizationKey = "searchInOrganization"
export const searchInStructureKey = "searchInStructure"

export async function searchInOrganization(organizationId: RecordId, q: string = "", filters = {}): Promise<ApiCollectionResponse<SearchAttributes, any, SearchAttributesMeta>> {
  const params = qs.stringify({ q, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.organizations.search(organizationId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}

export async function searchInStructure(structureId: RecordId, q: string = "", filters = {}): Promise<ApiCollectionResponse<SearchAttributes, any, SearchAttributesMeta>> {
  const params = qs.stringify({ q, ...filters }, { arrayFormat: 'brackets' });

  const response = await fetch(
    ROUTES.structures.search(structureId) + `?${params}`, { headers: defaultAuthHeaders() }
  )
  return await handleApiResponse(response);
}
