import {
  Badge,
  Card,
  CardBody,
  CardHeader,
  HStack,
  Heading,
  List,
  ListItem,
  Stack,
  TableProps,
  Text,
} from '@chakra-ui/react'

import {
  useReactTable,
  getCoreRowModel,
  createColumnHelper,
} from '@tanstack/react-table'
import { ApiCollectionResponse, ApiResource } from '@/utils/api'
import { SearchAttributes, SearchAttributesMeta } from '@/interfaces/search'
import { Link, useParams } from 'react-router-dom'

import { CollectionTable } from '@/components/CollectionTable/CollectionTable'
import entities from '@/utils/labels'
import Loader from '@/components/Loader/Loader'


const columnHelper = createColumnHelper<ApiResource<SearchAttributes, SearchAttributesMeta>>()


export interface SearchTableProps extends TableProps {
  data: ApiCollectionResponse<SearchAttributes, unknown, SearchAttributesMeta>,
  onClose: () => void
}

export const SearchTable = ({ data, onClose }: SearchTableProps) => {

  const { orgId } = useParams()

  return (
  <Stack spacing={2}>
    {data.data.slice(0, 8).map((searchResult) => {
      const item = searchResult.attributes.type ? entities[searchResult.attributes.type.toLowerCase()] : null

      return item ? (<Card onClick={onClose} as={Link} to={item.link(orgId, searchResult.id)} variant={'filled'} key={searchResult.id} size="sm">
        <CardHeader>
          <HStack justifyContent={'space-between'}>
            <Heading lineHeight={1} fontSize="lg">{searchResult.attributes?.name}</Heading>
            <Badge color={item.color}>{item.label}</Badge>
          </HStack>
        </CardHeader>
        <CardBody>
          <Text>{searchResult.attributes?.description}</Text>
        </CardBody>
      </Card>
    ) : <Loader />}
  
  )}
    {data.data.length === 0 && (
      <Text color='gray.600' textAlign={'center'} py={4}>Aucun résultat</Text>
    )}
  </Stack>
  )
}
