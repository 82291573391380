import { Button, ButtonProps, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, VStack, useDisclosure } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { TbQrcode } from "react-icons/tb"
import { EquipmentAttributes } from "@/interfaces/equipment"
import QRCode from "react-qr-code";
import { BASE_API_URL, BASE_APP_URL, goEquipmentRoute } from "@/constants/routes";
import { useMemo } from "react";

export interface ShowEquipmentRideQRCodeModalProps extends ButtonProps {
  equipment: EquipmentAttributes | undefined
}

export const ShowEquipmentRideQRCodeModal = ({
  equipment,
  ...props
}: ShowEquipmentRideQRCodeModalProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  const { orgId } = useParams()

  const onImageCownload = () => {
    const svg = document.getElementById("QRCode");

    if (svg) {
      const svgData = new XMLSerializer().serializeToString(svg);
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx?.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `QRcode-${equipment?.identifier}-${equipment?.serial}`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    }
  };

  // console.log(BASE_APP_URL)

  const url = useMemo(() => BASE_APP_URL + goEquipmentRoute(orgId, equipment?.slug), [orgId, equipment])

  return (
    <>
      <Button isDisabled={!equipment} variant={'white'} leftIcon={<TbQrcode />} onClick={onOpen} {...props}>QR Code</Button>

      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>QR Code de réservation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text textColor={'fg.subtle'} mb={3}>Imprimez le QR Code ci-dessous, et positionnez-le dans le véhicule afin de permettre la réservation lors du départ.</Text>
            <VStack>
              <QRCode
                id="QRCode"
                value={url}
              />
              <Link href={url} target="_blank" rel='noopener' mb={3} textDecoration={'underline'}>{url}</Link>
            </VStack>
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="brand" mr={3} onClick={onImageCownload}>
              Télécharger
            </Button>
            <Button mr={3} onClick={onClose}>
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}