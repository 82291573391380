import { EquipmentAvailability, EquipmentEvent, EquipmentState } from "@/interfaces/equipment";
import { MissionState } from "@/interfaces/mission";
import { MissionEquipmentState } from "@/interfaces/missionEquipment";
import R from 'react-icons'
import { FaCheck, FaHome } from "react-icons/fa";
import { TbAmbulance, TbCalendarCheck, TbFlag, TbForbid, TbHomeCheck, TbHourglassEmpty, TbMapQuestion, TbPackageOff, TbPencil, TbPlayerPlayFilled, TbTruckDelivery, TbTruckReturn } from "react-icons/tb";

export type StateCategory = 'waiting' | 'action_required' | 'in_progress' | 'finished' | 'cancelled'

export interface StateLabel {
  label: string
  icon: R.IconType
  color: string
  order: number
  category: StateCategory
}

export type EquipmentEventMetaLabel = 'notes' | 'destination'

export type FieldType = 'string' | 'location'

export interface StateLabelWithFields extends StateLabel {
  fields: { [key: string]: FieldType }
}

export type StateKey = EquipmentState | EquipmentAvailability | MissionState | MissionEquipmentState

export const STATES_LABELS: { [key in StateKey]: StateLabel } = {
  good: {
    label: 'Bon état',
    icon: FaCheck,
    color: 'green',
    order: 0,
    category: "waiting"
  },
  available: {
    label: 'Disponible',
    icon: FaHome,
    color: 'green',
    order: 0,
    category: "waiting"
  },
  planned: {
    label: 'Planifié',
    icon: TbPencil,
    color: 'gray',
    order: 0,
    category: "waiting"
  },
  to_review: {
    label: 'À vérifier',
    icon: TbFlag,
    color: 'orange',
    order: 20,
    category: "action_required"
  },
  bad: {
    label: 'Endommagé',
    icon: TbFlag,
    color: 'red',
    order: 10,
    category: "action_required"
  },
  on_mission: {
    label: 'En mission',
    icon: TbAmbulance,
    color: 'blue',
    order: 5,
    category: "in_progress"
  },
  lost: {
    label: 'Perdu',
    icon: TbMapQuestion,
    color: 'orange',
    order: 15,
    category: "action_required"
  },
  broken: {
    label: 'Cassé',
    icon: TbPackageOff,
    color: 'orange',
    order: 15,
    category: "action_required"
  },
  draft: {
    label: 'Brouillon',
    icon: TbPencil,
    color: 'gray',
    order: 0,
    category: "waiting"
  },
  pending: {
    label: 'En préparation',
    icon: TbHourglassEmpty,
    color: 'cyan',
    order: 0,
    category: "in_progress"
  },
  running: {
    label: 'En cours',
    icon: TbPlayerPlayFilled,
    color: 'blue',
    order: 5,
    category: "in_progress"
  },
  finished: {
    label: 'Terminé',
    icon: FaCheck,
    color: 'green',
    order: 8,
    category: "finished"
  },
  cancelled: {
    label: 'Annulé',
    icon: TbForbid,
    color: 'red',
    order: 15,
    category: "cancelled"
  },
  requested: {
    label: 'Demandé',
    icon: TbHourglassEmpty,
    color: 'cyan',
    order: 1,
    category: "in_progress"
  },
  accepted: {
    label: 'Accepté',
    icon: TbCalendarCheck,
    color: 'blue',
    order: 2,
    category: "in_progress"
  },
  declined: {
    label: 'Refusé',
    icon: TbForbid,
    color: 'gray',
    order: 15,
    category: "cancelled"
  },
  traveling_to_base: {
    label: 'En route',
    icon: TbTruckDelivery,
    color: 'teal',
    order: 3,
    category: "in_progress"
  },
  on_base: {
    label: 'Sur site',
    icon: TbHomeCheck,
    color: 'purple',
    order: 6,
    category: "in_progress"
  },
  returning_to_origin: {
    label: 'En retour',
    icon: TbTruckReturn,
    color: 'teal',
    order: 7,
    category: "in_progress"
  },
  returned: {
    label: 'De retour',
    icon: FaCheck,
    color: 'green',
    order: 9,
    category: "finished"
  },
  expired: {
    label: 'Périmé',
    icon: TbPackageOff,
    color: 'orange',
    order: 15,
    category: "action_required"
  },
  reformed: {
    label: 'Jeté',
    icon: TbPackageOff,
    color: 'orange',
    order: 15,
    category: "finished"
  },
  away: {
    label: 'Voyage',
    icon: TbTruckDelivery,
    color: 'purple',
    order: 3,
    category: "in_progress"
  }
}

export const EVENT_LABELS: { [key in EquipmentEvent]: StateLabelWithFields } = {
  good: {
    label: 'Marquer comme bon',
    icon: FaCheck,
    color: 'green',
    order: 0,
    category: "waiting",
    fields: {
      notes: "string"
    }
  },
  to_review: {
    label: 'À vérifier',
    icon: TbFlag,
    color: 'orange',
    order: 10,
    category: "action_required",
    fields: {
      notes: "string"
    }
  },
  mark_bad: {
    label: 'Marquer comme Inutilisable',
    icon: TbFlag,
    color: 'red',
    order: 22,
    category: "action_required",
    fields: {
      notes: "string"
    }
  },
  expire: {
    label: 'Marquer comme périmé',
    icon: TbPackageOff,
    color: 'orange',
    order: 150,
    category: "action_required",
    fields: {
      notes: "string"
    }
  },
  mark_reformed: {
    label: 'Jeter',
    icon: TbPackageOff,
    color: 'gray',
    order: 160,
    category: "action_required",
    fields: {
      notes: "string"
    }
  },
  review: {
    label: 'Vérifier',
    icon: TbCalendarCheck,
    color: 'blue',
    order: 20,
    category: "in_progress",
    fields: {
      notes: "string"
    }
  },
  move: {
    label: 'Déplacer',
    icon: TbTruckDelivery,
    color: 'blue',
    order: 9,
    category: "in_progress",
    fields: {
      destination: "location",
      notes: "string",
    }
  },
  to_mission: {
    label: 'Parti en mission',
    icon: TbTruckDelivery,
    color: 'blue',
    order: 100,
    category: "in_progress",
    fields: {
      notes: "string",
      destination: "location"
    }
  },
  loose: {
    label: 'Perdu',
    icon: TbPackageOff,
    color: 'red',
    order: 170,
    category: "action_required",
    fields: {
      notes: "string"
    }
  },
  retreive: {
    label: 'Retrouvé',
    icon: FaCheck,
    color: 'green',
    order: 15,
    category: "action_required",
    fields: {
      notes: "string"
    }
  },
  return: {
    label: 'De retour',
    icon: TbTruckReturn,
    color: 'teal',
    order: 7,
    category: "in_progress",
    fields: {
      notes: "string"
    }
  }
}