import { Button, Divider, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, VStack } from "@chakra-ui/react"
import { Form, Formik, FormikHelpers } from "formik"
import { RecordId } from "@/interfaces/id"
import { NewTakeoutMissionField } from "./NewTakeoutMissionField"
import { EquipmentAttributes } from "@/interfaces/equipment"
import { VehiculesListQueryParams } from "@/pages/Vehicules/VehiculesList"

export interface EquipmentTakeoutFormParams { 
  mission_id: RecordId
}

export interface VehiculeReservationBoxModalProps {
  equipmentId: RecordId,
  equipment?: EquipmentAttributes,
  queryParams?: VehiculesListQueryParams | null,
  onClose: () => void,
  onOpen: () => void,
  isOpen: boolean,
  onTakeout: (equipmentId: RecordId, missionId: RecordId) => Promise<void>
}

export const VehiculeReservationBoxModal = ({
  equipmentId,
  equipment,
  queryParams,
  onTakeout,
  isOpen,
  onOpen,
  onClose
}: VehiculeReservationBoxModalProps) => {


  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Réserver {equipment ? `${equipment.name}` : ''}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Formik<EquipmentTakeoutFormParams>
            initialValues={{
              mission_id: ''
            }}
            onSubmit={(
              values: EquipmentTakeoutFormParams,
              { setSubmitting }: FormikHelpers<EquipmentTakeoutFormParams>
            ) => {
              console.log("On submit: ", values)
              if (values.mission_id) {
                onTakeout(equipmentId, values.mission_id)
              }
              setSubmitting(false);
            }}
          >
            <Form>
              <VStack spacing={6} alignItems={'stretch'} justifyContent={'stretch'} flex={1}>
                <NewTakeoutMissionField
                  onTakeout={onTakeout}
                  name="mission_id"
                  equipment={equipment}
                  queryParams={queryParams}
                />
                <Divider />
                <VStack spacing={3} justifyContent={'space-between'} alignItems={'stretch'} flex={1} alignContent={'stretch'}>
                  <Button colorScheme='brand' type="submit">Réserver</Button>
                  <Button onClick={onClose} variant='outline'>Annuler</Button>
                </VStack>
              </VStack>
            </Form>
          </Formik>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}