// import "instantsearch.css/themes/algolia-min.css";

import { Badge, Box, HStack, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { VehiculesListQueryParams } from './VehiculesList';
import { TbCalendar, TbCheck, TbHome } from 'react-icons/tb';
import { formatDateShort } from '@/utils/datesFormat';
import { parseISO } from 'date-fns';
import { useUser } from '@/hooks/auth';
import { useMemo } from 'react';
import { VehiculesListFiltersModal } from './VehiculesListFiltersModal';



export interface VehiculesListActiveFiltersProps {
  params?: VehiculesListQueryParams
}
export const VehiculesListActiveFilters = ({ params }: VehiculesListActiveFiltersProps) => {

  const { data: user } = useUser();

  const structureName = useMemo(() => user?.meta.user_structures?.find(e => e.structure_id.toString() === params?.structure_id)?.structure.short_name, [user, params])

  console.log({ usable: params?.usable })
  return (
    <Box overflowX={'auto'}>
      <HStack gap={3} overflowX={'auto'} maxW={'100%'}>
        {params?.available_between?.start_date && params?.available_between?.end_date && (
          <Tag size='lg' variant='subtle' colorScheme='blue'>
            <TagLeftIcon as={TbCalendar} />
            <TagLabel>{formatDateShort(parseISO(params?.available_between?.start_date || ''))} - {formatDateShort(parseISO(params?.available_between?.end_date || ''))}</TagLabel>
          </Tag>
        )}
        {params?.structure_id && (
          <Tag size='lg' variant='subtle' colorScheme='blue'>
            <TagLeftIcon as={TbHome} />
            <TagLabel>{structureName}</TagLabel>
          </Tag>
        )}
        {params?.usable && (
          <Tag size='lg' variant='subtle' colorScheme='blue'>
            <TagLeftIcon as={TbCheck} />
            <TagLabel>Utilisable</TagLabel>
          </Tag>
        )}
      </HStack>
    </Box>
  );
}

