import { extendTheme } from '@chakra-ui/react'
import { theme as baseTheme } from './core'

import global from './global'
import colors from './colors'
import semanticTokens from './tokens'
import Button from './components/button'
import Link from './components/link'

import '@fontsource-variable/inter'
import '@fontsource/arvo';
import '@fontsource/poppins';
import '@fontsource/lato';


export const theme = extendTheme({
  ...baseTheme,
  colors,
  semanticTokens,
  styles: { global },
  config: {
    cssVarPrefix: 'nanuq',
    useSystemColorMode: false,
  },
  components: {
    ...baseTheme.components,
    Button,
    Link
  },
})
