import { Icon } from '@chakra-ui/icons'
import {
  Badge,
  Box,
  Button,
  Divider,
  Flex,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Stack,
} from '@chakra-ui/react'
import {
  FiHelpCircle,
  FiSearch,
  FiSettings,
} from 'react-icons/fi'
import {
  TbArrowLoopRight2,
  TbBoxSeam,
  TbBuildingWarehouse,
  TbCalendar,
  TbGauge,
  TbPackage,
  TbSearch,
  TbTruck
} from 'react-icons/tb'
import { NavLink as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { NavButton } from '../NavButton/NavButton'
import { UserProfile } from '../UserProfile/UserProfile'
import { ColorModeSwitcher } from '../ColorModeSwitcher/ColorModeSwitcher';

import { useLogout, useUser } from '@/hooks/auth';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { accountRoute, structuresRoute, settingsOrganizationRoute, equipmentsRoute, missionsRoute, generalSettingsOrganizationRoute, equipmentTypesRoute, structureRoute, equipmentsForStructureRoute, structureSummaryRoute, ridesRoute, reservationsRoute, planningForStructureRoute, vehiculesRoute, reservationsSearchRoute } from '@/constants/routes';
import { RiAccountCircleLine, RiLoginBoxLine } from 'react-icons/ri'
import { Logo } from '../Logo/Logo'
import { NavSubButton } from '../NavSubButton/NavSubButton'
import { GlobalSearch } from '../GlobalSearch/GlobalSearch'
import { StructureEquipmentsPage } from '@/pages/Equipments/StructureEquipments'

export const CURRENT_ENV = import.meta.env.VITE_APP_ENV || 'dev'

export const Sidebar = () => {

  const { data: user } = useUser()
  const logout = useLogout()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { organizations, getSelectedOrganization, ability } = useOrganizations()

  const onLogout = () => {
    logout.mutate({})
    navigate('/login')
  }

  const currentOrganization = getSelectedOrganization()
  const preferedStructure = user?.meta.user_structures && user?.meta.user_structures[0]

  return (
    <Flex as="section" minH="100vh" bg="bg-canvas">
      <Flex
        flex="1"
        // bg="bg-surface"
        bg="gray.10"
        overflowY="auto"
        // borderRightColor={useColorModeValue('sm', 'sm-dark')}
        // borderRightStyle={'solid'}
        // borderRightWidth={'1px'}
        maxW={{ base: 'full', sm: 'xs' }}
        py={{ base: '6', sm: '8' }}
        px={{ base: '4', sm: '6' }}
      >
        <Stack justify="space-between" spacing="1" maxW={'100%'}>
          <Stack spacing={{ base: '5', sm: '6' }} shouldWrapChildren>
            <HStack justifyContent="center">
              <Logo withIcon withText />
            </HStack>
            {CURRENT_ENV !== 'production' && <Badge colorScheme='purple' w={'100%'} textAlign={'center'}>{`${CURRENT_ENV}`.toLocaleUpperCase()}</Badge>}

            {ability?.can('delete', 'structure') && <GlobalSearch />}

            {currentOrganization && ability && (
              <Stack spacing="1">
                {ability?.can('reserve', 'organization') && preferedStructure && (
                  <NavButton label="Réserver" as={RouterLink} to={reservationsSearchRoute(currentOrganization?.id)} icon={TbSearch} />
                )}

                {ability?.can('reserve', 'organization') && preferedStructure && (
                  <NavButton label="Calendrier" as={RouterLink} to={reservationsRoute(currentOrganization?.id)} icon={TbCalendar} />
                )}

                { ability?.can('list', 'ride') && (
                  <NavButton label="Trajets" as={RouterLink} to={ridesRoute(currentOrganization?.id)} icon={TbGauge} />
                )}
                
                { ability?.can('list', 'mission') && (
                  <NavButton label="Missions" as={RouterLink} to={missionsRoute(currentOrganization?.id)} icon={TbArrowLoopRight2} />
                )}

                {ability?.can('list', 'equipment') && (<>
                  <NavButton label="Véhicules" as={RouterLink} to={vehiculesRoute(currentOrganization?.id)} icon={TbTruck} />
                </>)}

                {ability?.can('list', 'organizationsUser') && (<>
                  <NavButton label="Équipements" as={RouterLink} to={equipmentsRoute(currentOrganization?.id)} icon={TbPackage} />
                </>)}

                { ability?.can('list', 'structure') && (<>
                  <NavButton label="Structures" as={RouterLink} to={structuresRoute(currentOrganization?.id)} icon={TbBuildingWarehouse} />
                  <Box px="2" mt={-1} py="0">
                  {user?.meta.user_structures?.map(us => (
                    <NavSubButton key={us.structure_id} as={RouterLink} variant="link" to={structureSummaryRoute(currentOrganization?.id, us.structure_id)} label={us.structure?.short_name} />
                  ))}
                  </Box>
                </>)}
                { ability?.can('list', 'organizationsUser') && (
                  <NavButton label="Paramètres"as={RouterLink} to={settingsOrganizationRoute(currentOrganization?.id)} icon={FiSettings} />
                )}

                {pathname.startsWith(settingsOrganizationRoute(currentOrganization?.id)) && (
                  <Box px="2" mt={-1} py="0">
                    <NavSubButton as={RouterLink} variant="link" to={generalSettingsOrganizationRoute(currentOrganization?.id)} label="General" />
                    <NavSubButton as={RouterLink} variant="link" to={equipmentTypesRoute(currentOrganization?.id)} label="Équipements" />
                  </Box>
                )}
              </Stack>
            )}
          </Stack>
          <Stack spacing={{ base: '5', sm: '6' }}>

            {currentOrganization && (
              <Stack spacing="1">
                {/* <NavButton label="Help" icon={FiHelpCircle} /> */}
                <NavButton as={RouterLink} to={accountRoute(currentOrganization?.id)} label="Compte" icon={RiAccountCircleLine} />
              </Stack>
            )}

            <Divider />

            <Menu>
              <MenuButton
                as={Button}
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                {user && <UserProfile {...user.attributes} />}
              </MenuButton>
              <MenuList>
                <MenuItem as={RouterLink} to={accountRoute(getSelectedOrganization()?.id)} icon={<RiAccountCircleLine />}>Compte</MenuItem>
                <ColorModeSwitcher justifySelf="flex-end" />
                <MenuItem icon={<RiLoginBoxLine />} onClick={onLogout}>Déconnexion</MenuItem>
              </MenuList>
            </Menu>
          </Stack>
        </Stack>
      </Flex>
    </Flex>
  )
}