import {
  Box,
  HStack,
  IconButton,
  TableProps,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react'

import {
  Table,
} from '@tanstack/react-table'
import { TbChevronLeft, TbChevronRight, TbChevronsLeft, TbChevronsRight } from 'react-icons/tb'



export interface TablePaginationProps<T> extends TableProps {
  table: Table<T>
}

export function TablePagination<T>({ table }: TablePaginationProps<T>) {

  const isMobile = useBreakpointValue({ base: true, md: false })

  return (
    <Box px={{ base: '4', md: '6' }} pt={3} sx={{ position: 'sticky', background:'linear-gradient(0deg, rgba(255,255,255,0.9) 0%, rgba(255,255,255,0.9) 90%, rgba(255,255,255,0) 100%);', bottom: '0px'}} pb="5">
        <HStack spacing="3" justify="space-between">
          <HStack spacing="3" justify="space-between">
            <IconButton
              variant="secondary"
              aria-label='Premiere page'
              icon={<TbChevronsLeft />}
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            />

            <IconButton
              variant="secondary"
              aria-label='Précédent'
              icon={<TbChevronLeft />}
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            />
          </HStack>

          {!isMobile && (
            <Text color="fg.muted" textStyle="sm">
              Page {table.getState().pagination.pageIndex} sur{' '} {table.getPageCount()}
            </Text>
          )}

          <HStack spacing="3" justify="space-between">
            <IconButton
              variant="secondary"
              aria-label='Suivant'
              icon={<TbChevronRight />}
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            />

            <IconButton
              variant="secondary"
              aria-label='Derniere page'
              icon={<TbChevronsRight />}
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            />
          </HStack>
        </HStack>
      </Box>
  )
}
