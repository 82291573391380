
import { deleteRide, exportRides, getOrganizationRides, getOrganizationRidesKey } from '@/api/ride';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router-dom';
import { ApiResourceResponse, useOptimisticDeleteMutation } from '@/utils/api';
import { RideAttributes } from '@/interfaces/ride';
import { PaginationState } from '@tanstack/react-table';
import { RideTable } from '@/screens/RideTable/RideTable';
import { useState } from 'react';


export const RidesList = () => {

  const { orgId, structureId } = useParams()
  const queryClient = useQueryClient()

  const [{ pageIndex }, setPagination] =
    useState<PaginationState>({
      pageIndex: 0,
      pageSize: 10,
    })

  const queryKey = [getOrganizationRidesKey, orgId, structureId, pageIndex]
  const {
    data,
  } = useNanuqQuery(
    queryKey,
    () => orgId ? getOrganizationRides(orgId, pageIndex + 1, { structure: [structureId] }) : null
  )

  const deleteRideMutation = useOptimisticDeleteMutation<ApiResourceResponse<RideAttributes>, RecordId>(queryClient, deleteRide, queryKey)

  const onEdit = async () => {

  }

  const onDelete = async (rideId: RecordId) => {
    console.log("Delete !", { rideId })
    deleteRideMutation.mutate(rideId)
  }

  return (
    data?.data && <RideTable onDelete={onDelete} onEdit={onEdit} onPaginationChange={setPagination} data={data} />
  );
}
