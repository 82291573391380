
import { getEquipment, getEquipmentQuery } from '@/api/equipment';
import { BoxSection } from '@/components/BoxSection/BoxSection';
import { PageWithTitle } from '@/components/PageWithTitle/PageWithTitle';
import StateBadge from '@/components/StateBadge/StateBadge';
import { TabNav } from '@/components/TabNav/TabNav';
import { TabNavButton } from '@/components/TabNavButton/NavButton';
import { ShowEquipmentRideQRCodeModal } from '@/screens/ShowEquipmentRideQRCodeModal/ShowEquipmentRideQRCodeModal';
import { equipmentCalendarRoute, equipmentEditRoute, equipmentRoute, equipmentSummaryRoute, equipmentsForStructureRoute, structureRoute, structuresRoute, vehiculeCalendarRoute, vehiculeRoute, vehiculeSummaryRoute, vehiculesRoute } from '@/constants/routes';
import { useNanuqQuery } from '@/hooks/query';
import { RecordId } from '@/interfaces/id';
import { EquipmentAttributes, EquipmentAttributesMeta } from '@/interfaces/equipment';
import { ApiResourceResponse } from '@/utils/api';
import { QueryClient } from '@tanstack/react-query';
import { TbArrowLoopRight2, TbCalendar, TbEdit } from 'react-icons/tb';
import { Link, LoaderFunction, NavLink, Outlet, useLoaderData, useParams, useSearchParams } from 'react-router-dom';
import { Box, Button, HStack, Heading, Image, VStack, useBreakpointValue } from '@chakra-ui/react';
import { parseEquipmentQueryParams } from '@/utils/equipment';
import QueryString from 'qs';
import Marker from '@/components/Marker/Marker';
import { useLazyImage } from '@/hooks/useLazyImage';
import { VehiculeReservationBoxButton } from '@/screens/EquipmentActionButton/VehiculeReservationBox/Button';
import { useOrganizations } from '@/contexts/useOrganizationsContext';
import { VehiculeDetails } from '@/screens/VehiculeDetails/VehiculeDetails';


export const loader = (queryClient: QueryClient): LoaderFunction => (
  async ({ params }) => {
    const data = queryClient.getQueryData(getEquipmentQuery(params.equipmentId as RecordId).queryKey)
    if (!data) {
      return await queryClient.fetchQuery(getEquipmentQuery(params.equipmentId as RecordId))
    } 
    return data
  }
)

export const VehiculeShowPage = () => {

  const { orgId, equipmentId } = useParams()
  const { ability } = useOrganizations()
  const [searchParams, setSearchParams] = useSearchParams();

  const queryParams = parseEquipmentQueryParams(searchParams)
  const isMobile = useBreakpointValue({ base: true, sm: true, md: false, lg: false })

  const initialData = useLoaderData() as ApiResourceResponse<EquipmentAttributes, unknown, EquipmentAttributesMeta>;

  const { isLoading, data } = useNanuqQuery(getEquipmentQuery(equipmentId as RecordId).queryKey, () => equipmentId ? getEquipment(equipmentId) : null, { initialData })
  const { imageUrl, isImageLoaded } = useLazyImage(data?.data?.attributes.image_url, data?.data?.attributes.image_thumb_url)

  return (
    <PageWithTitle
      isLoading={isLoading}
      placeholderImage={data?.data?.attributes?.image_thumb_url}
      image={data?.data?.attributes?.image_url}
      breadcrumbs={[
        queryParams.available_between?.start_date ? (
          { label: 'Recherche', url: vehiculesRoute(orgId) + '?' + QueryString.stringify(queryParams) }) : (
          { label: 'Véhicules', url: vehiculesRoute(orgId) }
        ),
        { label: data?.data?.attributes?.name, url: vehiculeRoute(orgId, equipmentId) },
      ]}
      mainAction={<VehiculeReservationBoxButton
        equipmentId={data?.data?.id || ''}
        buttonLabel="Réserver"
        queryParams={queryParams}
        equipment={data?.data.attributes}
        variant={'solid'}
        colorScheme='brand'
        width={'100%'}
      />}
      title={<VStack zIndex={5} alignItems={'stretch'}>
        <HStack justifyContent={'center'}>
          {!isMobile && <Box
            h={'4em'}
            w={'4em'}
            borderRadius={'50%'}
            zIndex={1}
            backgroundSize={'cover'}
            transition="1s filter linear"
            backdropFilter={`blur(${isImageLoaded ? '0' : '5'}px)`}
            backgroundPosition={'center'}
            backgroundImage={`url(${imageUrl})`}
          />}
          <VStack>
            <Marker color={'yellow'}>{data?.data?.attributes?.name}</Marker>
          </VStack>
        </HStack>
      </VStack>}
      actions={[
        <ShowEquipmentRideQRCodeModal equipment={data?.data?.attributes} />,
        ability?.can('update', 'equipment', data?.data?.attributes) && <Button leftIcon={<TbEdit />} key={'edit'} as={Link} variant='secondary' to={equipmentEditRoute(orgId, equipmentId)}>Modifier</Button>
      ]}
      stackProps={{px: 0}}
    >
      <BoxSection px={0} py={0} bg={'bg.surface'}>
        <TabNav>
          <TabNavButton icon={TbArrowLoopRight2} label='Résumé' as={NavLink} to={vehiculeSummaryRoute(orgId, equipmentId)} />
          <TabNavButton icon={TbCalendar} label='Réservations' as={NavLink} to={vehiculeCalendarRoute(orgId, equipmentId)} />
        </TabNav>
        

          <Outlet />
      </BoxSection>
    </PageWithTitle>
  );
}

export default VehiculeShowPage;